'use client';
import React, { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';

import { ModeToggle } from '../(dark-and-light-mode)/ModeToggle';
import { Button } from '@/components/ui/button';
import CreateWorkspaceForm from '@/components/workspace/CreateWorkspaceForm';
import UserDropdownMenu from './UserDropdownMenu';
import usePageProtection from '@/components/usePageProtection';
import WorkspaceSwitcher from '../workspace/WorkspaceSwitcher';
import Logo from '../Logo';

const TopNavBar = ({ params }) => {
  const { data: session, status } = useSession();

  // To fix hydration error
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  // Use the following hook where route should be protected
  const { shouldHideUI } = usePageProtection();

  if (shouldHideUI || !isClient || status === 'unauthenticated') {
    return null;
  }

  return (
    <nav
      role="navigation" // Identifies it as a navigation landmark
      aria-label="Top Navigation Bar" // Describes the navigation context
      className="flex flex-col sm:flex-row items-center justify-between fixed z-50 top-0 px-2 sm:px-8 w-full h-32 sm:h-16 border-b shadow-sm bg-slate-50 dark:bg-background py-3 gap-y-1"
    >
      {/* Logo and Create Workspace Section */}
      <div className="flex items-center gap-x-6">
        {/* Logo */}
        <div className="hidden lg:block">
          <Logo />
        </div>

        {/* Create Workspace Button */}
        <Button
          aria-label="Create a new workspace" // Accessibility label for the button
          variant="primary"
          type="button"
          asChild
          size="sm"
          className="text-sm font-bold text-teal-800 dark:text-gray-100 hover:text-teal-900 dark:hover:text-gray-200"
        >
          <CreateWorkspaceForm />
        </Button>
      </div>

      {/* Navigation Controls */}
      <div className="flex items-center gap-x-6">
        {/* Workspace Switcher */}
        <div className="flex items-center ml-auto gap-x-2">
          <WorkspaceSwitcher />
        </div>

        {/* Dark/Light Mode Toggle */}
        <div className="flex items-center ml-auto gap-x-2">
          <ModeToggle />
        </div>

        {/* User Dropdown */}
        <div className="flex items-center ml-auto gap-x-2">
          <UserDropdownMenu />
        </div>
      </div>
    </nav>
  );
};

export default TopNavBar;
