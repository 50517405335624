'use client';
import { useState, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter, usePathname } from 'next/navigation';
import useSWR from 'swr';
import useWorkspaceStore from '@/lib/useWorkspaceStore';

import { Skeleton } from '@/components/ui/skeleton';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

const fetcher = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch workspaces list');
  }
  return response.json();
};

const WorkspaceSwitcher = () => {
  const { data: session } = useSession();
  const router = useRouter();
  const pathname = usePathname();
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const { workspaceId, setWorkspaceId } = useWorkspaceStore();
  const [isLoading, setIsLoading] = useState(true);

  // Fetching All Workspaces for the current User or Owner
  const ownerId = session?.user?.id;
  const { data: workspaceData, error: workspaceDataError } = useSWR(
    () => (ownerId ? `/api/get-all-workspaces/?ownerId=${ownerId}` : null),
    fetcher,
  );

  useEffect(() => {
    if (workspaceData) {
      setWorkspaces(workspaceData);
      setIsLoading(false);

      const workspaceSlug = pathname.split('/')[2];
      const initialWorkspace = workspaceData.find(
        (ws) => ws.workspaceUrlSlug === workspaceSlug,
      );
      if (initialWorkspace) {
        // console.log('Initial workspace found:', initialWorkspace);
        setSelectedWorkspace(initialWorkspace.id);
        setWorkspaceId(initialWorkspace.id); // Set workspace ID in global state
      } else {
        // console.log('No initial workspace found for slug:', workspaceSlug);
        setSelectedWorkspace(null);
      }
    }

    if (workspaceDataError) {
      console.error('Error fetching workspace:', workspaceDataError.message);
    }
  }, [workspaceData, pathname, workspaceDataError, setWorkspaceId]);
  // console.log(
  //   `ownerId and workspaceData in Workspace Switcher: ownerId=${ownerId}, workspaceData=${workspaceData}`,
  // );

  const handleWorkspaceChange = (selectedWorkspaceId) => {
    const selectedWorkspace = workspaces.find(
      (workspace) => workspace.id === selectedWorkspaceId,
    );

    if (selectedWorkspace) {
      // console.log('Selected workspace:', selectedWorkspace);
      setSelectedWorkspace(selectedWorkspaceId);
      setWorkspaceId(selectedWorkspaceId); // Set workspace ID in global state

      const workspaceUrlSlug = selectedWorkspace.workspaceUrlSlug;

      if (workspaceUrlSlug) {
        router.push(`/workspaces/${workspaceUrlSlug}`);
        // Add a small delay to allow the state to fully update
        // setTimeout(() => {
        //   router.push(`/workspaces/${workspaceUrlSlug}`);
        // }, 500); // 500ms delay to allow for proper state updates
      } else {
        console.error(
          'Workspace URL Slug for the selected workspace is missing.',
        );
      }
    } else {
      console.error('Selected workspace not found.');
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="space-y-1">
          <Skeleton className="h-4 w-[250px]" />
          <Skeleton className="h-4 w-[240px]" />
        </div>
      ) : (
        <Select onValueChange={handleWorkspaceChange} value={selectedWorkspace}>
          <SelectTrigger
            aria-label="workspace dropdown"
            className="w-[195px] text-base font-bold text-teal-600 hover:text-muted-foreground dark:text-muted-foreground"
          >
            <SelectValue placeholder="Select Workspace">
              {selectedWorkspace
                ? workspaces.find((ws) => ws.id === selectedWorkspace)?.title
                : 'Select Workspace'}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel className="text-base font-bold text-emerald-600 px-4 ml-4">
                Workspaces
              </SelectLabel>
              <hr />
              {workspaces.length > 0 ? (
                workspaces.map((workspace) => (
                  <SelectItem
                    key={workspace.id}
                    value={workspace.id}
                    aria-label="workspace dropdown"
                    className="text-base font-bold text-teal-600 !hover:text-rose-500 dark:hover:text-muted-foreground px-4 cursor-pointer space-x-4"
                  >
                    {workspace.title}
                  </SelectItem>
                ))
              ) : (
                <div className="text-muted-foreground font-semibold">
                  No workspaces available
                </div>
              )}
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
    </div>
  );
};

export default WorkspaceSwitcher;
