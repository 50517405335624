'use client';
import { useEffect, useId, useState } from 'react';
import { useSession, signOut } from 'next-auth/react';
import axios from 'axios';
import Link from 'next/link';
import {
  CreditCard,
  LayoutDashboard,
  LifeBuoy,
  LogOut,
  Settings,
  User,
  Users,
} from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { RiCoupon5Line } from 'react-icons/ri';

const UserDropdownMenu = () => {
  const { data: session } = useSession();
  const [hasSharedWorkspaces, setHasSharedWorkspaces] = useState(false);
  const uniqueId = useId(); // Generates a unique ID

  useEffect(() => {
    const checkSharedWorkspaces = async () => {
      if (session?.user?.email) {
        try {
          const response = await axios.get('/api/get-a-team-invitation');
          const data = response.data;

          if (!data || data.length === 0) {
            setHasSharedWorkspaces(false);
            return;
          }

          const acceptedInvites = data.filter(
            (invite) => invite.invitationAccepted,
          );

          setHasSharedWorkspaces(acceptedInvites.length > 0);
        } catch (error) {
          console.error('Error fetching shared workspaces:', error);
        }
      }
    };

    checkSharedWorkspaces();
  }, [session]);

  const userName = session?.user?.fullName;

  const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts.map((part) => part[0]).join('');
    return initials;
  };

  const nameInitials = userName ? getInitials(userName) : '';

  const userRole =
    session?.user?.userRole === 'superAdmin' ||
    session?.user?.userRole === 'admin' ||
    session?.user?.userRole === 'editor' ||
    session?.user?.userRole === 'partner' ||
    session?.user?.userRole === 'investor' ||
    session?.user?.userRole === 'accounts';

  return (
    <DropdownMenu area-label="dropdown-menu">
      <DropdownMenuTrigger asChild>
        <div
          role="button" // Correct ARIA role for trigger
          aria-haspopup="menu" // Indicates this element controls a menu
          aria-expanded="false" // Reflects the current state of the menu (closed)
          id={`dropdown-trigger-${uniqueId}`} // Unique ID for the trigger
          aria-controls={`dropdown-content-${uniqueId}`} // Links trigger with the content for accessibility
          className="flex items-center justify-between gap-x-1"
        >
          <span className="flex items-center justify-center bg-black dark:bg-slate-800 text-slate-200 dark:text-muted-foreground font-semibold text-xs rounded-full cursor-pointer p-1 w-8 h-8">
            {nameInitials}
          </span>
          <Button
            variant="ghost"
            area-hidden="true" // Marks the button as hidden to assistive technologies
            className="hidden sm:block text-base font-bold text-teal-600 hover:text-muted-foreground dark:text-muted-foreground"
          >
            {session?.user?.fullName || 'CB User'}
          </Button>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        aria-labelledby={`dropdown-trigger-${uniqueId}`} // Correctly links the trigger to the menu content
        id={`dropdown-content-${uniqueId}`} // Unique ID for the menu content
        role="menu" // Correct ARIA role for the dropdown content
        className="w-64"
      >
        <DropdownMenuLabel
          role="presentation" // Ensures the label is presented correctly in assistive technologies
          variant="outline"
          className="font-semibold text-muted-foreground"
        >
          <span className="text-teal-600 font-semibold">
            {session?.user?.fullName || 'Your Name'}
          </span>{' '}
          <span className="text-xs text-muted-foreground font-medium">
            {session?.user?.email || 'Your Email'}
          </span>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />{' '}
        {/* Separates menu sections for visual and navigational clarity */}
        <DropdownMenuGroup role="group">
          {' '}
          {/* Grouping related menu items */}
          <Link href="/profile" className="no-underline">
            <DropdownMenuItem
              role="menuitem" // Correct ARIA role for each item in the menu
              className="cursor-pointer"
            >
              <User className="mr-2 size-6 text-teal-700" />
              <span className="font-medium text-muted-foreground">Profile</span>
              <DropdownMenuShortcut className="font-bold text-red-700">
                ⇧⌘P
              </DropdownMenuShortcut>
            </DropdownMenuItem>
          </Link>
          <Link href="/billing" className="no-underline">
            <DropdownMenuItem role="menuitem" className="cursor-pointer">
              <CreditCard className="mr-2 size-5 text-teal-700" />
              <span className="font-medium text-muted-foreground">Billing</span>
              <DropdownMenuShortcut className="font-bold text-red-700">
                ⇧⌘P
              </DropdownMenuShortcut>
            </DropdownMenuItem>
          </Link>
          <Link href="/cname-domain" className="no-underline">
            <DropdownMenuItem role="menuitem" className="cursor-pointer">
              <Settings className="mr-2 size-5 text-teal-700" />
              <span className="font-medium text-muted-foreground">
                cName/ White Label
              </span>
              <DropdownMenuShortcut className="font-bold text-red-700">
                ⇧⌘P
              </DropdownMenuShortcut>
            </DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>
        <DropdownMenuSeparator /> {/* Separates menu sections */}
        <DropdownMenuGroup role="group">
          <Link href="/redeem-onsite-coupon" className="no-underline">
            <DropdownMenuItem
              role="menuitem"
              className="flex items-start cursor-pointer"
            >
              <RiCoupon5Line className="size-5 text-teal-600 mr-2" />
              <span className="font-medium text-muted-foreground">
                Got a Coupon?{' '}
                <span className="font-semibold text-rose-600">Try it!</span>
              </span>
              <DropdownMenuShortcut className="font-bold text-red-700">
                ⇧⌘P
              </DropdownMenuShortcut>
            </DropdownMenuItem>
          </Link>
          {/* Keeping your commented code here */}
          {/* <Link href="/redeem-appsumo-coupon" className="no-underline">
        <DropdownMenuItem role="menuitem" className="flex items-start cursor-pointer">
          <RiCoupon5Line className="size-5 text-teal-600 mr-2" />
          <span className="font-medium text-muted-foreground">
            Got AppSumo Coupon? <br />
            <span className="font-semibold text-rose-600">Try it!</span>
          </span>
          <DropdownMenuShortcut className="font-bold text-red-700">⇧⌘P</DropdownMenuShortcut>
        </DropdownMenuItem>
      </Link> */}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup role="group">
          {userRole && (
            <Link href="/admin-dashboard" className="no-underline">
              <DropdownMenuItem role="menuitem" className="cursor-pointer">
                <LayoutDashboard className="mr-2 size-5 text-teal-600" />
                <span className="font-medium text-muted-foreground">
                  Admin Dashboard
                </span>
                <DropdownMenuShortcut className="font-bold text-red-700">
                  ⇧⌘P
                </DropdownMenuShortcut>
              </DropdownMenuItem>
            </Link>
          )}

          <DropdownMenuSeparator />

          {hasSharedWorkspaces && (
            <Link href={`/shared-workspaces`} className="no-underline">
              <DropdownMenuItem role="menuitem" className="cursor-pointer">
                <Users className="mr-2 size-5 text-teal-600" />
                <span className="font-medium text-muted-foreground">
                  Shared Workspaces
                </span>
                <DropdownMenuShortcut className="font-bold text-red-700">
                  ⇧⌘P
                </DropdownMenuShortcut>
              </DropdownMenuItem>
            </Link>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup role="group">
          <Link href="/support" className="no-underline">
            <DropdownMenuItem role="menuitem" className="cursor-pointer">
              <LifeBuoy className="mr-2 size-5 text-teal-600" />
              <span className="font-medium text-muted-foreground">Support</span>
              <DropdownMenuShortcut className="font-bold text-red-700">
                ⇧⌘P
              </DropdownMenuShortcut>
            </DropdownMenuItem>
          </Link>

          <DropdownMenuSeparator />

          <DropdownMenuItem
            role="menuitem"
            className="cursor-pointer group-hover:bg-red-300"
            onClick={() => signOut()}
          >
            <LogOut className="mr-2 size-5 text-rose-600 group-hover:bg-red-300" />
            <span className="font-semibold text-muted-foreground group-hover:bg-red-300">
              Log out
            </span>
            <DropdownMenuShortcut className="font-bold text-red-700">
              ⇧⌘P
            </DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserDropdownMenu;
