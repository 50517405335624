'use client';
import React, { useState, useEffect, useMemo } from 'react';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import useSWR from 'swr';

const fetcher = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch: ${response.status}`);
  }
  return response.json();
};

const Logo = () => {
  const { data: session, status } = useSession();
  const [hostname, setHostname] = useState(null); // Null if on localhost
  const [cnameTitle, setCnameTitle] = useState('Classified Billing');
  const [cnameLogoUrl, setCnameLogoUrl] = useState(
    '/images/classified-billing-Logo.png', // Default logo
  );

  // Detect hostname only on the client
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentHostname = window.location.hostname;
      // Skip API calls for localhost
      if (currentHostname !== 'localhost' && currentHostname !== '127.0.0.1') {
        setHostname(currentHostname);
      }
    }
  }, []);

  // Fetch domain data if hostname is valid and user is authenticated
  const { data: cnameBrandData, error: cnameBrandError } = useSWR(
    status === 'authenticated' && hostname
      ? `/api/get-a-domain?userId=${session?.user?.id}`
      : null,
    fetcher,
  );

  // Update title and logo when custom domain data is available
  useEffect(() => {
    if (cnameBrandData) {
      setCnameTitle(cnameBrandData.cnameBrandTitle || 'Classified Billing');
      setCnameLogoUrl(
        cnameBrandData.cnameBrandLogo || '/images/classified-billing-Logo.png',
      );
    } else if (cnameBrandError) {
      console.warn('Domain data not found. Using default values.');
    }
  }, [cnameBrandData, cnameBrandError]);

  // Check if this is a custom domain
  const isCustomDomain = useMemo(
    () =>
      cnameBrandData &&
      hostname &&
      hostname.includes(cnameBrandData.domainName),
    [hostname, cnameBrandData],
  );

  return (
    <div>
      {isCustomDomain ? (
        <Link href={`https://${cnameBrandData?.domainName}`}>
          <div className="hover:opacity-75 transition flex items-center justify-center gap-x-2">
            <Image
              src={cnameLogoUrl}
              alt={cnameTitle}
              height={35}
              width={35}
              unoptimized
              className="rounded-sm object-cover"
            />
            <p className="text-base text-teal-600 dark:text-muted-foreground font-bold">
              {cnameTitle}
            </p>
          </div>
        </Link>
      ) : (
        <Link href="/">
          <div className="hover:opacity-75 transition flex items-center justify-center gap-x-1">
            <Image
              src="/images/classified-billing-Logo.png" // Default logo path
              alt="Classified Billing Logo"
              height={35}
              width={35}
              unoptimized
              className="rounded-sm object-cover"
            />
            <p className="text-base text-teal-600 font-bold dark:text-muted-foreground">
              Classified Billing
            </p>
          </div>
        </Link>
      )}
    </div>
  );
};

export default Logo;
